<script>
import WorkspaceListTabTemplate from '@/components/workspace/WorkspaceListsTabTemplate'
export default {
  name: 'WorkspaceListsTab',
  components: {
    WorkspaceListTabTemplate
  },
  computed: {
    userLists () {
      return this.$store.getters.getWorkspaceLists[this.$route.params.id]
    },
    shouldForceOnlyPublic () {
      return this.$store.getters.getIndividualWorkspace.id !== parseInt(this.$route.params.id)
    }
  },
  methods: {
    pushToAddList () {
      const routesToPush = [
        { dir: 'workspace.public', path: 'list.workspace.public.management' },
        { dir: 'default', path: 'list.workspace.management' }
      ]
      this.pushToRouteName(routesToPush)
    },
    updateLists (filter = {}) {
      const options = { filter: { ...filter, forceOnlyPublic: this.shouldForceOnlyPublic }, workspaceId: this.$route.params.id }
      this.$store.dispatch('attemptGetWorkspaceLists', options)
    }
  },
  created () {
    this.updateLists()
  }
}
</script>
<template>
  <div class="workspace-list--tab">
    <workspace-list-tab-template
      :lists="userLists"
      @updateList="updateLists"
    ></workspace-list-tab-template>
  </div>
</template>
